import React, { useEffect } from "react";



import Form from "./Form";

const Contact = () => {
  return (
    <>



      <div className="rainbow-split-area rainbow-section-gap" id="about">
        <div className="container">
          <div className="rainbow-splite-style">
            <div className="split-wrapper">
              <div className="row g-0 radius-10 align-items-center">
                <div className="col-lg-12 col-xl-6 col-12">
                  <div className="split-inner">
                    <h4
                      className="title"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="200"
                    >
                      See SecurX in action!
                    </h4>
                    <p
                      className="description"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="300"
                    >

                    </p>
                    <ul
                      className="split-list"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="350"
                    >
                      <p
                      >
                        Request a free capabilities report or a quote today.
                      </p>

                      <li>
                        <h5
                          className="title fs-1"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Discover
                        </h5>
                        <p
                        >
                          We'll identify the most critical vulnerabilities in your applications before real hackers do.
                        </p>
                      </li>
                      <li>
                        <h5
                          className="title fs-1"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Mitigate
                        </h5>
                        <p
                        >
                          Remediate the discovered vulnerabilities and request a fix verification.
                        </p>
                      </li>
                      <li>
                        <h5
                          className="title fs-1"
                          data-sal="slide-up"
                          data-sal-duration="400"
                          data-sal-delay="200"
                        >
                          Repeat
                        </h5>
                        <p
                        >
                          Repeating the process helps enhance your security posture and keeps you up-to-date with the latest techniques and strategies employed by attackers.
                        </p>
                      </li>
                    </ul>
                    <div
                      className="view-more-button mt--35"
                      data-sal="slide-up"
                      data-sal-duration="400"
                      data-sal-delay="400"
                    ></div>
                  </div>
                </div>
                <div className="col-lg-12 col-xl-6 col-12">
                  <Form />
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
    </>
  );
};

export default Contact;
