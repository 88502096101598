// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { addDoc, collection } from 'firebase/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCzrPBouI_8oI_gDwimIs58KfPF-DILCb8",
    authDomain: "securx-8ea24.firebaseapp.com",
    projectId: "securx-8ea24",
    storageBucket: "securx-8ea24.appspot.com",
    messagingSenderId: "144511835086",
    appId: "1:144511835086:web:1d9b74182abda91860299f"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// const db = getFirestore(app);


export default app;