import React, { useEffect, useState } from 'react';
import boxedLogo from "../../public/images/logo/boxed-logo.png";
import Image from "next/image";
import sal from 'sal.js';
import { addDoc, collection, serverTimestamp } from 'firebase/firestore'; // Import serverTimestamp
import { app } from '@/Firebase/firebase';
import { getFirestore } from "firebase/firestore";
import { useRouter } from 'next/router';

function Form() {
    const router = useRouter();
    const { plan } = router.query;

    const [formData, setFormData] = useState({
        plan: plan || 'premium',  // Default to premium if no plan is specified
        firstName: '',
        lastName: '',
        email: '',
        title: '',
        company: '',
        message: '',
    });

    useEffect(() => {
        sal();

        const cards = document.querySelectorAll(".bg-flashlight");

        cards.forEach((bgflashlight) => {
            bgflashlight.onmousemove = function (e) {
                let x = e.pageX - bgflashlight.offsetLeft;
                let y = e.pageY - bgflashlight.offsetTop;

                bgflashlight.style.setProperty("--x", x + "px");
                bgflashlight.style.setProperty("--y", y + "px");
            };
        });
    }, []);

    const handleChange = (e) => {
        const { name, value, type } = e.target;
        setFormData(prevData => ({ ...prevData, [name]: type === 'radio' ? value : value }));
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const db = getFirestore(app);
        const currentDate = new Date();
        const formattedDate = currentDate.toLocaleString();

        try {
            await addDoc(collection(db, 'contacts'), {
                ...formData,
                createdAt: formattedDate// Add createdAt field with server timestamp
            });
            alert('Message sent successfully!');
            setFormData({
                plan: 'premium',
                firstName: '',
                lastName: '',
                email: '',
                title: '',
                company: '',
                message: ''
            });
        } catch (error) {
            console.error('Error submitting message:', error);
            alert('Failed to send message. Please try again later.');
        }
    };

    return (
        <div className="signup-area" id="contact">
            <div className="sign-up-wrapper rainbow-section-gap">
                <div className="sign-up-box">
                    <div className="signup-box-top top-flashlight light-xl">
                        <Image
                            src={boxedLogo}
                            width={476}
                            height={158}
                            alt="sign-up logo"
                        />
                    </div>
                    <div className="separator-animated animated-true"></div>
                    <div className="signup-box-bottom">
                        <div className="signup-box-content">
                            <h4 className="title">We'd like to hear from you!</h4>
                            <form onSubmit={handleSubmit}>
                                <div className="input-section">
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="plan"
                                            id="basicPlan"
                                            value="basic"
                                            checked={formData.plan === 'basic'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="basicPlan">
                                            Regular Pack
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="plan"
                                            id="premiumPlan"
                                            value="premium"
                                            checked={formData.plan === 'premium'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="premiumPlan">
                                            New Customer Pack
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name="plan"
                                            id="otherInquiry"
                                            value="otherInquiry"
                                            checked={formData.plan === 'premium'}
                                            onChange={handleChange}
                                        />
                                        <label className="form-check-label" htmlFor="premiumPlan">
                                            Other Inquiry
                                        </label>
                                    </div>
                                </div>
                                <div className="input-section password-section">
                                    <div className="icon">
                                        <i className="feather-user"></i>
                                    </div>
                                    <input type="text" name="firstName" placeholder="First Name" value={formData.firstName} onChange={handleChange} />
                                </div>
                                <div className="input-section password-section">
                                    <div className="icon">
                                        <i className="feather-user"></i>
                                    </div>
                                    <input type="text" name="lastName" placeholder="Last Name" value={formData.lastName} onChange={handleChange} />
                                </div>
                                <div className="input-section mail-section">
                                    <div className="icon">
                                        <i className="feather-mail"></i>
                                    </div>
                                    <input type="email" name="email" placeholder="Business Email" value={formData.email} onChange={handleChange} />
                                </div>
                                <div className="input-section password-section">
                                    <div className="icon">
                                        <i className="feather-help-circle"></i>
                                    </div>
                                    <input type="text" name="title" placeholder="Title" value={formData.title} onChange={handleChange} />
                                </div>
                                <div className="input-section password-section">
                                    <div className="icon">
                                        <i className="feather-briefcase"></i>
                                    </div>
                                    <input type="text" name="company" placeholder="Company" value={formData.company} onChange={handleChange} />
                                </div>
                                <div className="input-section password-section">
                                    <textarea name="message" id="message" cols="30" rows="10" placeholder="Message" value={formData.message} onChange={handleChange}></textarea>
                                </div>
                                <button type="submit" className="btn-default">
                                    Submit
                                </button>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Form;
